
import React from 'react'
import "./footer.css"
import facebookimg from "..//..//Assets/images//OBJECTS.png"
import instaimg from "..//..//Assets/images//OBJECTS (1).png"
import youtubeimg from "..//..//Assets/images//OBJECTS (2).png"
import linkedinimg from "..//..//Assets/images//OBJECTS (3).png"
import twitterimg from "..//..//Assets/images//Group 584.png"
import designimgl from "..//..//Assets/images//Group (1).png"
import designimgr from "..//..//Assets/images//Group.png"
// import GoogleMap from "../../Components/Contact/Map";
import mapimg from "..//..//Assets/images/map.png"
// import {MapPin } from "react-feather";
import { useWindowWidthAndHeight } from "../../../CustomHook";

const Footer = () => {
  return (
    <div className='Footer-wrapper'>
        <img className='designimgl' src={designimgl} alt="" />
        <img className='designimgr' src={designimgr} alt="" />
<div className="Footer-container">
        <div className="footer-left">
        <div className="footertxt-container">

   <h1>Contact Us</h1>
   <div className="address-contact">

   <div className="address">
   <p>NSS Office, <br />
2nd floor, Multi Activity Centre,
IIT Roorkee, Roorkee-247667
Uttarakhand, IN</p>
   </div>
   <div className="contact">
<p>
nss@iitr.ac.in <br />
+91-8271934956
</p>

   </div>
   </div>
        </div>
        <div className="sm-icons">
        <div>
        <a
            href="https://www.facebook.com/nssiitr"
            target="blank"
            rel="noreferrer"
            className="th-link"
          >
             <img src={facebookimg} alt="" />
          </a>
          </div>
        <div>
          <a
            href="https://twitter.com/NSS_IITR?s=09"
            target="blank"
            rel="noreferrer"
            className="th-link"
          >
           <img src={twitterimg} alt="" />
          </a>
          </div>
          <div>
          <a
            href="https://www.instagram.com/nssiitr/"
            target="blank"
            rel="noreferrer"
            className="th-link"
          >
             <img src={instaimg} alt="" />
          </a>
          </div>
          <div>
          <a
            href="https://www.linkedin.com/company/nssiitr/mycompany/"
            target="blank"
            rel="noreferrer"
            className="th-link"
          >
          <img src={linkedinimg} alt="" />
          </a>
          </div>
<div>
          <a
            href="https://www.youtube.com/channel/UCOwle_PBTdDRQC4HCJijBKA"
            target="blank"
            rel="noreferrer"
            className="th-link"
          >
             <img src={youtubeimg} alt="" />
          </a>
          </div>
        </div>    
        </div>
      
        
        <div className="footer-right">
       <div className="map-container">
<img src={mapimg} alt="" className="map-img" />
       </div>
        </div>
        </div>
        <div className="line-footer"></div>
        <div className="copyright-footer">Copyright © 2022 NSS IITR All Rights Reserved.</div>
     
    </div>
  )
}

export default Footer




















// // import React from "react";
// // import {
// //   Twitter,
// //   Facebook,
// //   Instagram,
// //   Youtube,
// //   Linkedin,
// //   Mail,
// //   Phone,
// //   MapPin,
// // } from "react-feather";
// // import { Link } from "react-router-dom";
// // import { useWindowWidthAndHeight } from "../../../CustomHook";
// // import slogan_image from "../../Assets/images/slogan.svg";
// // import GetInTouch from "../../Components/Contact/GetInTouch";
// // import GoogleMap from "../../Components/Contact/Map";

// // const Footer = () => {
// //   let [width] = useWindowWidthAndHeight();
// //   return (
// //     <div className="th-footer-container pt-5 pb-md-3 pb-3">
// //       <div className="container">
// //         <div className="row th-footer-wraper pb-4">
// //           <div
// //             className="col-md-6 pr-md-6 col-sm-10 pb-0"
// //             style={{ maxWidth: "fit-content" }}
// //           >
// //             <div>
// //               <img src={slogan_image} className="slogan-image" />
// //             </div>
// //             <br />
// //             <GoogleMap />
// //           </div>
// //           <div className="pt-md-3 pb-3 mx-5">
// //             <div className="col-md-12 col-sm-12">
// //               <h5 className="th-footer-slogan">
// //                 <b>Contact Us</b>
// //               </h5>
// //               <div className="d-flex align-items-center pt-md-3 pt-1 pb-3">
// //                 <MapPin
// //                   size={width > 768 ? 20 : 24}
// //                   className="mr-3 th-footer-contact"
// //                   color="black"
// //                   onClick={() => {
// //                     window.location.href =
// //                       "https://goo.gl/maps/NtHfdoBguiADxoAh7";
// //                   }}
// //                 />
// //                 <p className="th-footer-contact mb-0">
// //                   NSS Office, 2nd floor, Multi Activity Centre, <br /> IIT
// //                   Roorkee, 247667
// //                 </p>
// //               </div>
// //               <div className="d-flex align-items-center pb-3">
// //                 <Mail
// //                   size={width > 768 ? 20 : 24}
// //                   className="mr-3 th-footer-contact"
// //                   color="black"
// //                   onClick={() => {
// //                     window.location.href = `mailto:nss@iitr.ac.in`;
// //                   }}
// //                 />
// //                 <p className="th-footer-contact mb-0">nss@iitr.ac.in</p>
// //               </div>
// //               <div className="d-flex align-items-center pb-3">
// //                 <Phone
// //                   size={width > 768 ? 20 : 24}
// //                   className="mr-3 th-footer-contact"
// //                   color="black"
// //                   onClick={() => {
// //                     window.location.href = `tel: +91-9877247661`;
// //                   }}
// //                 />
// //                 <p className="th-footer-contact mb-0">+91-9877247661</p>
// //               </div>
// //             </div>
// //           </div>
// //           <div className="th-footer-contact-form">
// //             <GetInTouch />
// //           </div>
// //         </div>
// //         <hr />
// //         <div className="pt-0 d-flex justify-content-center align-items-center">
// //           <a
// //             href="https://www.facebook.com/nssiitr"
// //             target="blank"
// //             rel="noreferrer"
// //             className="th-link"
// //           >
// //             <Facebook
// //               size={width > 768 ? 36 : 28}
// //               className="mr-md-5 mr-4 th-footer-icon"
// //               color="black"
// //             />
// //           </a>
// //           <a
// //             href="https://twitter.com/NSS_IITR?s=09"
// //             target="blank"
// //             rel="noreferrer"
// //             className="th-link"
// //           >
// //             <Twitter
// //               size={width > 768 ? 36 : 28}
// //               className="mr-md-5 mr-4 th-footer-icon"
// //               color="black"
// //             />
// //           </a>
// //           <a
// //             href="https://www.instagram.com/nssiitr/"
// //             target="blank"
// //             rel="noreferrer"
// //             className="th-link"
// //           >
// //             <Instagram
// //               size={width > 768 ? 36 : 28}
// //               className="mr-md-5 mr-4 th-footer-icon"
// //               color="black"
// //             />
// //           </a>
// //           <a
// //             href="https://www.linkedin.com/company/nssiitr/mycompany/"
// //             target="blank"
// //             rel="noreferrer"
// //             className="th-link"
// //           >
// //             <Linkedin
// //               size={width > 768 ? 36 : 28}
// //               className="mr-md-5 mr-4 th-footer-icon"
// //               color="black"
// //             />
// //           </a>
// //           <a
// //             href="https://www.youtube.com/channel/UCOwle_PBTdDRQC4HCJijBKA"
// //             target="blank"
// //             rel="noreferrer"
// //             className="th-link"
// //           >
// //             <Youtube
// //               size={width > 768 ? 36 : 28}
// //               className="mr-md-5 mr-3 th-footer-icon"
// //               color="black"
// //             />
// //           </a>
// //         </div>
// //       </div>
// //     </div>
// //   );
// // };

// // export default Footer;
